.App {
  text-align: center;
  background-color: #4264a9;
  justify-content: center;
  font-size: 20px;
  color: white;
}

.App-logo {
  height: 40vmin;
  background-color: #4264a9;
}

.App-header {
  background-color: #4264a9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white;
}

.App-link {
  color: #09d3ac;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.6;
}

header {
  background: #f4f4f4;
  padding: 1rem;
  text-align: center;
}

.appcontainer {
  margin: auto;
  width: 365px !important;
  overflow: auto;
  padding: 3rem 2rem;
}

#my-form {
  padding: 1rem;
  background: #f4f4f4;
}

#my-form label {
  display: block;
  color: black;
}

#my-form input[type="number"] {
  width: 80%;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  text-align: center;
}

.btn {
  display: block;
  width: 80%;
  padding: 10px 15px;
  border: 0;
  background: #333 !important;
  color: #fff !important;
  border-radius: 5px;
  margin: auto;

  font-size: 14px;
}

.btn:hover {
  background: #444;
}

.bg-dark {
  background: #333;
  color: #fff;
}

.container {
  /* background: green; */
  border: 2px !important;
  border-color: white;
  display: grid;
  margin: auto;
  width: 350px;
  /* overflow: auto; */
  /* padding: 3rem 2rem; */
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
  grid-column-gap: 2px;
  grid-row-gap: 2px;
}

.spinner {
  height: 41px;
}
.col {
  color: red;
  background: #333 !important;
  border-radius: 5px;
  font-size: 14px;
}
